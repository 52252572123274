/*
--- 01 TYPOGRAPHY SYSTEM

- Font sizes (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights


- Line heights
Default: 1


- Letter spacing


--- 02 COLORS

- Primary: 
- Tints:
- Shades:
- Accents:
- Greys

--- 05 SHADOWS

--- 06 BORDER-RADIUS

--- 07 WHITESPACE

- Spacing system (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/

/**************************/
/* GENRAL REUSABLE COMPONENTS */
/**************************/
.container {
  width: 73rem;
  margin: 0 auto;
  margin-top: 2rem;
}
